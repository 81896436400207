import { useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSessionByIdQuery } from 'store/api/sessionsApi';
import Message from 'components/Message';
import styles from './ChatList.module.scss';

const ChatList = () => {
  const { sessionId } = useParams();
  const refAnchor = useRef<HTMLDivElement>(null);

  const { data: currentSession } = useGetSessionByIdQuery(sessionId || '', {
    skip: !sessionId,
  });

  const invitationMessage = useMemo(
    () => `Hi ${currentSession?.user}, what can I help you with today?`,
    [currentSession]
  );

  if (!sessionId || !currentSession) {
    return <div className={styles.root} />;
  }

  const { created_at, session_length, questions } = currentSession;

  return (
    <div className={styles.root}>
      <Message
        type="incoming"
        message={invitationMessage}
        createdAt={created_at}
      />

      {questions.map(
        ({ id, user_input, model_response, created_at }, index) => (
          <>
            <Message
              key={`${id}-outcoming`}
              type="outcoming"
              message={user_input}
              createdAt={created_at}
            />

            {model_response && (
              <Message
                key={`${id}-incoming`}
                type="incoming"
                message={model_response}
                order={`${index}/${session_length}`}
                createdAt={created_at}
              />
            )}
          </>
        )
      )}

      <div ref={refAnchor} />
    </div>
  );
};

export default ChatList;

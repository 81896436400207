import { configureStore } from '@reduxjs/toolkit';
import { sessionsApi } from './api/sessionsApi';
import { questionsApi } from './api/questionsApi';
import { metadataApi } from './api/metadataApi';

export const store = configureStore({
  reducer: {
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [questionsApi.reducerPath]: questionsApi.reducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([
      sessionsApi.middleware,
      questionsApi.middleware,
      metadataApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FeedbackFormData } from 'types';
import { ratingOptions } from 'constants/feedback';
import FeedbackRating from 'components/FeedbackRating';
import styles from './Feedback.module.scss';

const MIN_TEXTAREA_ROWS = 3;

const schema = yup.object().shape({
  ideal_response: yup.string().required(),
  understanding: yup.string().required(),
  helpfulness: yup.string().required(),
  coherence: yup.string().required(),
  completeness: yup.string().required(),
  accuracy: yup.string().required(),
  language_quality: yup.string().required(),
  relevance: yup.string().required(),
  user_experience: yup.string().required(),
  style: yup.string().required(),
  error_handling: yup.string().required(),
});

const Feedback = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FeedbackFormData>({
    // TODO(olha): TS issue https://github.com/react-hook-form/react-hook-form/issues/6679
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData: FeedbackFormData) => {
    console.log('formData :>> ', formData);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <p className={styles.title}>Rate the response</p>
      <p className={styles.note}>Lowest rating = 1</p>
      <p className={styles.note}>Highest ratting = 10</p>

      {ratingOptions.map((item) => (
        <FeedbackRating
          {...item}
          key={item.field}
          register={register}
          watch={watch}
          error={!!errors[item.field]}
        />
      ))}

      <div className={styles.field}>
        <legend
          className={classNames(styles.legend, {
            [styles.isRequired]: errors.ideal_response,
          })}
        >
          Ideal Respond
        </legend>

        <TextareaAutosize
          minRows={MIN_TEXTAREA_ROWS}
          className={styles.textarea}
          placeholder="Enter ideal output"
          {...register('ideal_response')}
        />
      </div>

      <div className={styles.actions}>
        <button className={styles.submitButton} type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Feedback;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './config';
import { Topic } from 'types';

enum MetadataTags {
  Topics = 'Topics',
}

export const metadataApi = createApi({
  reducerPath: 'metadataApi',
  baseQuery,
  tagTypes: [MetadataTags.Topics],
  endpoints: (build) => ({
    getTopics: build.query<Topic[], void>({
      query: () => ({
        url: '/metadata/topics',
      }),
      providesTags: [MetadataTags.Topics],
    }),

    getTopicById: build.query<Omit<Topic, 'id'>, string>({
      query: (id) => ({
        url: `/metadata/topics/${id}`,
      }),
      providesTags: [MetadataTags.Topics],
    }),

    createTopic: build.mutation<Topic, Topic>({
      query: (body) => ({
        url: '/metadata/topics',
        method: 'POST',
        body,
      }),
      invalidatesTags: [MetadataTags.Topics],
    }),

    updateTopic: build.mutation<
      string,
      { id: string; body: Omit<Topic, 'id'> }
    >({
      query: ({ id, body }) => ({
        url: `/metadata/topics/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [MetadataTags.Topics],
    }),
  }),
});

export const {
  useGetTopicsQuery,
  useGetTopicByIdQuery,
  useCreateTopicMutation,
  useUpdateTopicMutation,
} = metadataApi;

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateSessionMutation } from 'store/api/sessionsApi';
import TopicSelect from 'components/TopicSelect';
import styles from './ChatBar.module.scss';

const ChatBar = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const [topic, setTopic] = useState<string>('');

  const [createSession] = useCreateSessionMutation();

  const handleStart = async () => {
    // TODO: pass user & vendor
    const body = {
      topic,
      user: 'testUser',
      vendor: 'testVendor',
    };

    try {
      const { id } = await createSession(body).unwrap();
      navigate(`${id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.selectorWrapper}>
        <p>Choose a topic:</p>
        <TopicSelect onChange={setTopic} />
      </div>
      <button
        disabled={!!sessionId}
        onClick={handleStart}
        className={styles.startButton}
      >
        Start
      </button>
    </div>
  );
};

export default ChatBar;

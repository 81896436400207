import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from 'pages/LoginPage';
import ChatPage from 'pages/ChatPage';
import {
  Authenticator,
  withAuthenticator,
  WithAuthenticatorOptions,
} from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_OATH_DOMAIN}`, // TODO make dynamic based on stage.
      scope: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_NINJA_UI_URL}`,
      redirectSignOut: `${process.env.REACT_APP_NINJA_UI_URL}`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

<Authenticator></Authenticator>;

const App = () => {
  // TODO: remove after add auth functionality
  const isAuth = true;

  return (
    <Routes>
      {!isAuth ? (
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      ) : (
        <>
          <Route path="/manage-tasks">
            <Route index element={<ChatPage />} />
            <Route path={`:sessionId`} element={<ChatPage />} />
          </Route>
          <Route path="/" element={<Navigate to="/manage-tasks" replace />} />
          <Route
            path="/login"
            element={<Navigate to="/manage-tasks" replace />}
          />
        </>
      )}
      <Route path="*" element={<div>Page not found</div>} />
    </Routes>
  );
};

export default withAuthenticator(App, {
  includeGreetings: false,
} as WithAuthenticatorOptions);

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './config';
import { Question, QuestionRequest, Feedback } from 'types';

enum QuestionsTags {
  Questions = 'Questions',
}

export const questionsApi = createApi({
  reducerPath: 'questionsApi',
  baseQuery,
  tagTypes: [QuestionsTags.Questions],
  endpoints: (build) => ({
    createQuestion: build.mutation<Question, QuestionRequest>({
      query: (body) => ({
        url: 'questions',
        method: 'POST',
        body,
      }),
      invalidatesTags: [QuestionsTags.Questions],
    }),

    createFeedback: build.mutation<Question, { id: string; body: Feedback }>({
      query: (id) => ({
        url: `questions/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: [QuestionsTags.Questions],
    }),
  }),
});

export const { useCreateQuestionMutation, useCreateFeedbackMutation } =
  questionsApi;

import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { useCreateQuestionMutation } from 'store/api/questionsApi';
import { sessionsApi } from 'store/api/sessionsApi';
import ChatBar from 'components/ChatBar';
import Feedback from 'components/Feedback';
import ChatList from 'components/ChatList';
import ChatForm from 'components/ChatForm';
import styles from './Conversation.module.scss';

const Conversation = () => {
  const dispatch = useAppDispatch();
  const { sessionId } = useParams();

  const [createQuestion] = useCreateQuestionMutation();

  const handleMessageSend = async (value: string) => {
    if (!sessionId) {
      return;
    }

    try {
      await createQuestion({
        user_input: value,
        session_id: sessionId,
      }).unwrap();
      dispatch(sessionsApi.util.resetApiState());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.root}>
      <ChatBar />

      <div className={styles.container}>
        <div className={styles.chatContainer}>
          <ChatList />
          <ChatForm onSubmit={handleMessageSend} />
        </div>

        <Feedback />
      </div>
    </div>
  );
};

export default Conversation;

import classNames from 'classnames';
import { formatDateToString } from 'helpers';
import styles from './Message.module.scss';

type MessageType = 'incoming' | 'outcoming';

interface MessageProps {
  type: MessageType;
  order?: string;
  message: string;
  createdAt: string;
}

const Message = ({ type, order = '', message, createdAt }: MessageProps) => {
  return (
    <div className={classNames(styles.root, styles[type])}>
      <div className={styles.container}>
        <p className={styles.title}>
          {type === 'incoming' ? `Atlas ${order}` : 'You'}
        </p>
        <div className={styles.content}>
          <p>{message}</p>
          <p className={styles.footer}>{formatDateToString(createdAt)}</p>
        </div>
      </div>
    </div>
  );
};

export default Message;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './config';
import { SessionRequest, Session } from 'types';

enum SessionsTags {
  Session = 'Session',
}

export const sessionsApi = createApi({
  reducerPath: 'sessionsApi',
  baseQuery,
  tagTypes: [SessionsTags.Session],
  endpoints: (build) => ({
    getSessionById: build.query<Session, string>({
      query: (id) => ({
        url: `/sessions/${id}`,
      }),
      providesTags: [SessionsTags.Session],
    }),

    createSession: build.mutation<Session, SessionRequest>({
      query: (body) => ({
        url: 'sessions',
        method: 'POST',
        body,
      }),
      invalidatesTags: [SessionsTags.Session],
    }),

    completeSession: build.mutation<Session, string>({
      query: (id) => ({
        url: `sessions/complete/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: [SessionsTags.Session],
    }),
  }),
});

export const {
  useGetSessionByIdQuery,
  useCreateSessionMutation,
  useCompleteSessionMutation,
} = sessionsApi;

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { useGetTopicsQuery } from 'store/api/metadataApi';
import { useGetSessionByIdQuery } from 'store/api/sessionsApi';
import { SelectOption } from 'types';
import { getCustomStyles } from './helpers';
import styles from './TopicSelect.module.scss';

interface TopicSelectProps {
  onChange: (id: string) => void;
}

const TopicSelect = ({ onChange }: TopicSelectProps) => {
  const { sessionId } = useParams();

  const { data, isLoading } = useGetTopicsQuery();
  const { data: currentSession } = useGetSessionByIdQuery(sessionId || '', {
    skip: !sessionId,
  });

  const topicOptions: SelectOption[] = useMemo(
    () =>
      data
        ?.filter((item) => item.enabled)
        .map((item) => ({
          value: item.id,
          label: item.display_name,
        })) || [],
    [data]
  );

  const defaultValue = useMemo(
    () => topicOptions.find((item) => item.value === currentSession?.topic),
    [topicOptions, currentSession]
  );

  const handleChange = ({ value }: SelectOption) => {
    onChange(value);
  };

  return (
    <Select
      className={styles.root}
      options={topicOptions}
      isLoading={isLoading}
      value={defaultValue}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
      styles={getCustomStyles<SelectOption>()}
      isDisabled={!!sessionId}
    />
  );
};

export default TopicSelect;

import AppBar from 'components/AppBar';
import Conversation from 'components/Conversation';
import styles from './ChatPage.module.scss';

const ChatPage = () => {
  return (
    <div className={styles.root}>
      <AppBar />
      <Conversation />
    </div>
  );
};

export default ChatPage;
